页面：首页
描述：上方为导航栏，下方为子路由
时间：2020.01.25
<template>
  <div>
    <a-col type="flex">
      <div>
        <a-row class="headBar" type="flex">
          <router-link to="/">
            <img
              class="nav-logo"
              mode="aspectFill"
              :src="`${publicPath}logo/chamjoy.png`"
              alt="Chamjoy Mall"
            />
          </router-link>
          <a-row style="flex:1" type="flex" justify="space-between" align="middle">
            <div v-if="isShowMenu">
              <router-link class="link" :to="{ name: 'user' }">{{ hotel_name }}</router-link>
            </div>
            <div v-if="isShowMenu" class="search">
              <!-- <span class="right_link">
                <a-icon type="shopping-cart" />
                <router-link :to="{ name: 'cart' }" class="shopping-cart">购物车</router-link>
              </span>-->
              <router-link class="right_link" :to="{ name: 'orders' }">我的订单</router-link>
              <router-link class="right_link" :to="{ name: 'user' }">个人中心</router-link>
              <a class="right_link" href>下单指引</a>
              <a class="right_link" href>结算指引</a>
              <!-- <a-input-search
                class="right_search"
                :disabled="disabled"
                @search="search"
                style="width:300px"
                placeholder="在全部分类下搜索产品"
                enter-button
              />-->
            </div>
          </a-row>
        </a-row>
      </div>
      <div class="Second_HB">
        <div>
          <a-menu mode="horizontal">
            <a-menu-item key="mail" class="second_menu">
              <router-link to="/">商城首页</router-link>
            </a-menu-item>
            <a-sub-menu title="产品分类">
              <a-sub-menu v-for="c1 in data" :key="c1.id_product_category" :title="c1.name">
                <a-sub-menu
                  v-for="c2 in c1.children"
                  :key="c2.id_product_category"
                  :title="c2.name"
                >
                  <a-menu-item
                    v-for="c3 in c2.children"
                    :key="c3.id_product_category"
                    @click="to_c3(c3.id_product_category)"
                  >
                    <a>{{c3.name}}</a>
                  </a-menu-item>
                </a-sub-menu>
              </a-sub-menu>
            </a-sub-menu>
          </a-menu>
        </div>
        <a-input-search
          class="right_search"
          :disabled="disabled"
          @search="search"
          style="width:800px"
          placeholder="在全部分类下搜索产品"
          enter-button
        />
        <span class="right_link_cart">
          <a-icon type="shopping-cart" />
          <a class="shopping-cart">
            <router-link :to="{ name: 'cart' }">我的购物车</router-link>
          </a>
        </span>
        <span class="right_link_cart">
          <a class="shopping-cart">
            <router-link :to="{ name: 'customcart' }">定制购物车</router-link>
          </a>
        </span>
      </div>
    </a-col>
    <div style="min-width: 1260px">
      <router-view ref="view" />
    </div>
    <div class="footBar">
      <a-row style="margin: 20px auto; max-width: 1200px;">
        <a-col :span="4">
          <img class="logo" :src="`${publicPath}logo/chamjoy_foot.png`" alt="舒悦酒店" />
        </a-col>
        <a-col :span="3">
          <div style="margin-bottom: 10px">关于我们</div>
          <h6>
            <a href="https://www.chamjoy.com">公司简介</a>
          </h6>
          <h6>
            <a href="https://mooc.chamjoy.com">营业执照</a>
          </h6>
        </a-col>
        <a-col :span="3">
          <div style="margin-bottom: 10px">服务指南</div>
          <h6>
            <a href="https://mooc.chamjoy.com">付款及开票流程</a>
          </h6>
          <h6>
            <a href="https://mooc.chamjoy.com">供应商结算流程</a>
          </h6>
          <h6>
            <a href="https://www.chamjoy.com">采购下单流程</a>
          </h6>
          <h6>
            <a href="https://mooc.chamjoy.com">退换货流程</a>
          </h6>
          <h6>
            <a href="https://mooc.chamjoy.com">客户服务</a>
          </h6>
        </a-col>
        <a-col :span="3">
          <div style="margin-bottom: 10px">平台政策</div>
          <h6>
            <a class="title" target="_blank" href="https://store.chamjoy.com/fuwuxieyi.html">服务协议</a>
          </h6>
          <h6>
            <a
              class="title"
              target="_blank"
              href="https://store.chamjoy.com/yinsizhengche.html"
            >隐私政策</a>
          </h6>
          <h6>
            <a class="title" target="_blank" href="https://store.chamjoy.com/zhucexieyi.html">注册协议</a>
          </h6>
          <h6>
            <a class="title" target="_blank" href="https://store.chamjoy.com/jiaoyiguize.html">交易规则</a>
          </h6>
        </a-col>
        <a-col :span="3">
          <div style="margin-bottom: 10px">商务合作</div>
          <h6>
            <a class="title" href="https://store.chamjoy.com/supplier/login">供应商注册</a>
          </h6>
          <h6>
            <a class="title" href="https://store.chamjoy.com/builder/login">施工方注册</a>
          </h6>
        </a-col>
        <a-col :span="3">
          <div style="margin-bottom: 10px">友情链接</div>
          <h6>
            <!-- <a href="tel:0571-1234567">0571-1234567</a> -->
            <a href="https://www.chamjoy.com">舒悦酒店</a>
          </h6>
          <h6>
            <!-- <a href="mailto:contact@chamjoy.com">contact@chamjoy.com</a> -->
            <a href="https://mooc.chamjoy.com">舒悦商学院</a>
          </h6>
        </a-col>
        <a-col :span="5">
          <div style="margin-bottom: 10px">微信公众号</div>
          <img class="qrcode" :src="`${publicPath}logo/qrcode.jpg`" alt="二维码" />
        </a-col>
      </a-row>
      <div class="copyright">
        <a-row class="words" type="flex" justify="space-between">
          <div>舒悦隐私政策</div>
          <div>|</div>
          <div>权益保障承诺书</div>
          <a href="https://beian.miit.gov.cn/">浙ICP备20026879号-1</a>
          <div>Copyright © 2020-2021 杭州享住信息技术有限公司 版权所有</div>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      isShowMenu: false,
      disabled: false, // 搜索按钮禁用
      name: '',
      contact: '',
      input: '',
      data: [] // 存放分类数据
    }
  },

  methods: {
    search(e) {
      if (!e.match(/^[ ]*$/)) {
        // 若搜索内容不为空则跳转
        this.disabled = true
        this.$router.replace({ name: 'index', params: { forceStop: true } })
        setTimeout(() => {
          this.$router.replace({ name: 'products', params: { name: e } })
        }, 10)
        setTimeout(() => {
          this.disabled = false
        }, 500)
      }
    },
    to_c2(id) {
      this.$router.replace({ name: 'index', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: id
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    },
    to_c3(id) {
      this.$router.replace({ name: 'index', params: { forceStop: true } })
      setTimeout(() => {
        this.$router.replace({
          name: 'products',
          query: {
            category: id
          }
        })
      }, 10)
      setTimeout(() => {
        this.disabled = false
      }, 500)
    }
  },

  mounted() {
    const user = this.getUser()
    // console.log(user)
    if (user) {
      this.id_card_name = user.id_card_name
      this.hotel_name = user.hotel_name
      this.isShowMenu = true
    }
    this.get('getCategory', { status: 1 }).then((res) => {
      this.data = res
    })
  }
}
</script>

<style scoped>
.headBar {
  padding: 13px 50px;
  background-color: #fff;
  max-width: 1600px;
  min-width: 900px;
  margin: 0 auto;
}
.Second_HB {
  display: flex;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1600px;
  min-width: 900px;
  align-items: flex-end;
}
.nav-logo {
  left: 0;
  width: 180px;
  height: 56.3px;
  margin-right: 20px;
  cursor: pointer;
}
.link {
  line-height: 56.3px;
  font-size: 20px;
  color: #000;
  text-decoration: none;
  margin-left: 30px;
  margin-right: 10px;
}

.link:hover {
  color: #347f9b;
  font-weight: 600;
  box-shadow: -10px 5px 20px -17px rgba(0, 0, 0, 0.64);
  transform: scale(1.2);
}
.right_link {
  color: #000;
  margin-left: 30px;
  margin-right: 10px;
  font-size: 20px;
}
.right_link_cart {
  display: flex;
  color: #000;
  align-items: center;
  margin: 0 0 5px 20px;
  font-size: 20px;
}
.right_link_cart:hover {
  color: #347f9b;
  font-weight: 600;
  /* box-shadow: -10px 5px 20px -17px rgba(0, 0, 0, 0.64); */
  /* transform: scale(1.2); */
}
.right_link:hover {
  color: #347f9b;
  font-weight: 600;
  box-shadow: -10px 5px 20px -17px rgba(0, 0, 0, 0.64);
  transform: scale(1.2);
}
.right_search {
  margin: 0 0 5px 190px;
  display: flex;
  align-items: center;
}
/* 购物车图标处 */
.shopping-cart {
  color: #000;
  font-size: 20px;
}
/* .shopping-cart:hover {
  color: #347f9b;
} */
.shopping-cart:hover {
  color: #347f9b;
  font-weight: 600;
  /* transform: scale(1.2); */
}
.max-width {
  margin: 20px auto;
  max-width: 1200px;
  min-width: 1100px;
}
.footBar {
  margin-top: 30px;
  font-size: 20px;
  background-color: #01111f;
  text-align: center;
  color: #fff;
  padding: 10px 0;
}
.footBar .logo {
  margin-top: 10px;
  width: 100px;
}
.footBar .qrcode {
  width: 120px;
}
.copyright {
  border-top: 1px solid #444;
  margin-top: 40px;
}
.copyright .words {
  margin: 10px auto;
  max-width: 780px;
  font-size: 14px;
  color: #889;
}
</style>
